<template>
  <section>
    <a-divider class="dividerTitle text-outline">
      {{ $t("pages.forgot-password.title") }}
    </a-divider>
    <!-- <a-form-item>
      <div class="subtitle">
        {{ $t('pages.forgot-password.subtitle') }}
      </div>
    </a-form-item> -->
    <a-form
      id="components-form-demo-normal-forgot-password"
      :form="form"
      class="forgot-password-form"
      @submit="handleSubmit"
    >
      <div class="subtitle text-outline">
        {{ $t("pages.forgot-password.subtitle") }}
      </div>
      <a-form-item>
        <a-input
          v-decorator="[
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: this.$t('ui.provideValidEmail'),
                },
                {
                  required: true,
                  message: this.$t('ui.ProvideEmail'),
                },
              ],
            },
          ]"
          placeholder="E-mail"
        >
          <a-icon
            slot="prefix"
            type="user"
            style="color: rgba(0, 0, 0, 0.25)"
          />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" :disabled="loading">
          {{ this.$t("buttons.submit") }}
        </a-button>
      </a-form-item>
    </a-form>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  middleware: "notAuthenticated",
  data() {
    return {
      loading: false,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values)
          this.loading = true;

          const response = await this["authCustom/forgotPasswordAction"]({
            email: values.email,
          });
          this.loading = false;

          if (response) {
            this.$notification["success"]({
              message: this.$t("ui.SuccessOccurred"),
              description: false,
            });
            this.$router.push("/");
          } else {
            this.$notification["error"]({
              message: this.$t("ui.ErrorAccountNotFound"),
              description: false,
            });
            this.$router.push("/auth/registration");
          }
          // this.$router.push(('/'))
          // }
        }
      });
    },
    ...mapActions(["authCustom/forgotPasswordAction"]),
  },
};
</script>

<style scoped>
.forgot-password-form {
  margin: 20px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .dividerTitle {
    display: none;
  }
}

.subtitle {
  margin-bottom: 15px;
}
</style>
