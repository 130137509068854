var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a-divider',{staticClass:"dividerTitle text-outline"},[_vm._v(" "+_vm._s(_vm.$t("pages.forgot-password.title"))+" ")]),_c('a-form',{staticClass:"forgot-password-form",attrs:{"id":"components-form-demo-normal-forgot-password","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"subtitle text-outline"},[_vm._v(" "+_vm._s(_vm.$t("pages.forgot-password.subtitle"))+" ")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                type: 'email',
                message: this.$t('ui.provideValidEmail'),
              },
              {
                required: true,
                message: this.$t('ui.ProvideEmail'),
              },
            ],
          },
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                type: 'email',\n                message: this.$t('ui.provideValidEmail'),\n              },\n              {\n                required: true,\n                message: this.$t('ui.ProvideEmail'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"E-mail"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.loading}},[_vm._v(" "+_vm._s(this.$t("buttons.submit"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }